import { template as template_a39e9d38a209499e826d781a261d4a4b } from "@ember/template-compiler";
const FKText = template_a39e9d38a209499e826d781a261d4a4b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
