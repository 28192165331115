import { template as template_4af4a35642a64af8bae6d9afb2f411e4 } from "@ember/template-compiler";
const FKLabel = template_4af4a35642a64af8bae6d9afb2f411e4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
