import { template as template_9c1211da476c41c6a382caca51c8f3bf } from "@ember/template-compiler";
const FKControlMenuContainer = template_9c1211da476c41c6a382caca51c8f3bf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
